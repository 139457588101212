import { projectApi } from 'api'
import { ProjectAttribute } from 'types/projects/attributes'

export type AttributeByProject = { value: string } & ProjectAttribute

interface Props {
  projectId: string
}

export const fetchAttributesByProjectApi = ({ projectId }: Props) =>
  projectApi.get<{ data: AttributeByProject[] }>(`/project/${projectId}/attributes`)
