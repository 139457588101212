import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as zod from 'zod'

import { PatchPhase } from 'api/canvas/fetchers/patchPhaseApi'
import { usePatchPhaseApi } from 'api/canvas/mutation/usePatchPhaseApi'
import { Flex } from 'components/common/flex/Flex'
import { FormDatepicker } from 'components/form/formDatepicker/FormDatepicker'
import { FormInput } from 'components/form/formInput/FormInput'
import { FormSelect } from 'components/form/formSelect/FormSelect'
import { FormTextareaInput } from 'components/form/formTextareaInput/FormTextareaInput'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { ACTION_ANALYTICS, CanvasItemType } from 'constants/analytics'
import { useForm } from 'hooks/form/useForm'
import { getCommonProjectAnalyticsData, useTrackAction } from 'hooks/useAnalytics'
import useEventSource from 'hooks/useEventSource'
import { useProject } from 'hooks/useProject'
import { useTimeTracker } from 'hooks/useTimeTracker'
import { useToast } from 'hooks/useToast'
import styles from 'pages/project/components/canvas/components/editPhaseModal/EditPhaseModal.module.scss'
import { SelectPerson } from 'pages/project/components/canvas/components/selectPerson/SelectPerson'
import {
  ResponsibleUser,
  useGetBaseFormValues,
  getFormattedDates,
} from 'pages/project/components/canvas/components/selectPerson/utils'
import { invalidateCanvas } from 'pages/project/components/canvas/linearCanvas/components/item/utils'
import { LinearPhase } from 'pages/project/components/canvas/utils'
import { useHasProjectRole } from 'pages/project/hooks/useHasProjectRole'
import { createProjectModal } from 'pages/project/utils/createProjectModal'
import { ProjectRole } from 'types/permissions/permissions'
import { PhaseStatus } from 'types/projects/workflow'
import { makeStringShorter } from 'utils/common'
import { NiceModalWrappedProps } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  phase: LinearPhase
  isDisabled?: boolean
  projectId: string
  isWrikeConnected?: boolean
  isTemplate?: boolean
}

const phaseValidationSchema = zod
  .object({
    name: zod
      .string()
      .min(1, 'Name should contain at least 1 character')
      .max(64, 'Name should have 64 characters at most'),
    description: zod.string().max(1000, 'Description should have 1000 characters at most').optional(),
  })
  .passthrough()

const EditPhaseModal = ({
  phase,
  onClose,
  onCloseComplete,
  isOpen,
  isDisabled,
  projectId,
  isWrikeConnected,
  isTemplate,
}: Props) => {
  const { showToast } = useToast()
  const { calculateTimeDifferenceInSeconds } = useTimeTracker()
  const { t } = useTranslation()
  const { trackAction } = useTrackAction()

  const { me } = useHasProjectRole()
  const eventSource = useEventSource()
  const projectContext = useProject()
  const defaultValues = {
    ...phase,
    description: phase.description || '',
    ...useGetBaseFormValues(phase, projectId),
    status: phase.status,
  }

  const form = useForm({ defaultValues, validationSchema: phaseValidationSchema })

  const { mutateAsync: handleUpdatePhase } = usePatchPhaseApi()

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form

  const onSubmit = handleSubmit(async ({ dueDate, description, name, assignUser: assignedUsers, status }) => {
    try {
      const assignUser = assignedUsers[0] as ResponsibleUser | undefined

      let updateBody: PatchPhase = {
        phaseId: phase.id,
        name: name.trim(),
        description: description?.trim() || null,
      }

      // other fields are not allowed for templates
      if (!isTemplate) {
        updateBody = {
          ...updateBody,
          ...getFormattedDates(dueDate),
          assignUser: assignUser?.email || null,
          status,
        }
      }

      await handleUpdatePhase(updateBody)

      await invalidateCanvas(!assignUser?.isMember)
      showToast({
        type: 'success',
        message: t('project.canvas.toast.update_phase', { query: makeStringShorter(name) }),
      })

      if (projectContext?.project) {
        const { project } = projectContext
        const analyticsData = {
          ...getCommonProjectAnalyticsData(project, me),
          type: CanvasItemType.PHASE,
          responsible_person_role: !assignUser?.isMember ? ProjectRole.VIEWER : assignUser.role,
          event_source: eventSource,
        }

        trackAction(ACTION_ANALYTICS.ACTION_WORKFLOW_ITEM_EDIT, analyticsData)
      }

      onClose()
    } catch (e) {
      showToast({
        type: 'error',
        message: t('project.canvas.toast.failed_operation_edit', { query: 'phase' }),
      })
      console.log(e)
    }
  })

  const handleCancelEdit = () => {
    onClose()

    if (projectContext) {
      const { project } = projectContext

      const analyticsData = {
        ...getCommonProjectAnalyticsData(project, me),
        type: CanvasItemType.PHASE,
        duration_in_seconds: calculateTimeDifferenceInSeconds(),
        event_source: eventSource,
      }

      trackAction(ACTION_ANALYTICS.ACTION_WORKFLOW_ITEM_EDIT_CANCELLED, analyticsData)
    }
  }

  return (
    <FormProvider {...form}>
      <SideModal
        open={isOpen}
        formConfig={{ onSubmit }}
        onWppSideModalClose={handleCancelEdit}
        onWppSideModalCloseComplete={onCloseComplete}
        size="m"
      >
        <WppTypography slot="header" type="2xl-heading">
          {t('modals.settings.phase_settings')}
        </WppTypography>
        <Flex slot="body" direction="column" gap={24}>
          <FormInput
            name="name"
            labelConfig={{ text: t('project.phase.settings_modal.name_label') }}
            placeholder={t('project.phase.settings_modal.name_placeholder')}
            required
            disabled={isDisabled}
            data-testid="modal-phase-name"
          />
          <FormTextareaInput
            name="description"
            labelConfig={{ text: t('project.phase.settings_modal.description_label') }}
            placeholder={t('project.phase.settings_modal.description_placeholder')}
            warningThreshold={980}
            charactersLimit={1000}
            disabled={isDisabled}
            data-testid="modal-phase-description"
          />

          {!isTemplate && (
            <>
              <SelectPerson disabled={isDisabled} projectId={projectId} isWrikeConnected={isWrikeConnected} />
              <FormDatepicker
                className={styles.datePicker}
                name="dueDate"
                placeholder={t('project.phase.settings_modal.dates_placeholder')}
                labelConfig={{ text: t('project.phase.settings_modal.dates_label') }}
                range
                disabled={isDisabled}
              />
              <FormSelect
                name="status"
                required
                labelConfig={{ text: 'Status' }}
                options={Object.values(PhaseStatus).map(status => ({
                  label: t(`project.phase.status.${status}`),
                  value: status,
                }))}
                data-testid="modal-phase-status"
              />
            </>
          )}
        </Flex>
        <Flex slot="actions" justify="end" gap={12}>
          <WppButton variant="secondary" size="m" onClick={handleCancelEdit}>
            {t('common.btn_cancel')}
          </WppButton>
          <WppButton variant="primary" size="m" type="submit" loading={isSubmitting} disabled={isDisabled}>
            {t('common.btn_save')}
          </WppButton>
        </Flex>
      </SideModal>
    </FormProvider>
  )
}
export const { showModal: showEditPhaseModal } = createProjectModal<Props>(EditPhaseModal, 'edit-phase-modal')
