import * as zod from 'zod'

import { ProjectAttribute } from 'types/projects/attributes'
import { ProjectType } from 'types/projects/projects'

function getRefineSystemRequired(systemAttribute: ProjectAttribute) {
  return [
    (value: any) => {
      if (!systemAttribute?.required) return true

      return Array.isArray(value) ? !!value?.length : !!value
    },
    {
      message: 'This field is required',
    },
  ] as const
}

export const modalValidationScheme = (otherFields: string[], attributes: ProjectAttribute[] = []) => {
  const systemValidation = attributes.reduce<Record<string, ProjectAttribute>>((acc, attribute) => {
    return { ...acc, [attribute.contractName]: attribute }
  }, {})

  // CONTEXT_WORKSPACE : "context" can be changed to any other key, so this comment is needed to track the key across project
  const { contextWorkspace, name, description, startDate_endDate, type, processType, ...restAttr } = systemValidation

  return zod
    .object({
      name: zod
        .string()
        .min(3, 'This field is required and should have at least 3 symbols')
        .max(100, 'Name should be from 3 to 100 characters long'),
      type: zod.string().refine(...getRefineSystemRequired(systemValidation.type)),
      description: zod
        .string()
        .max(1000, 'Description should have 1000 characters at most')
        .refine(...getRefineSystemRequired(systemValidation.description)),
      dueDate: zod
        .date()
        .array()
        .optional()
        .refine(...getRefineSystemRequired(systemValidation['startDate_endDate'])),
      processType: zod
        .string()
        .optional()
        .refine(...getRefineSystemRequired(systemValidation.processType)),
      // hierarchy
      ...otherFields.reduce(
        (previousValue, currentValue, currentIndex) => ({
          ...previousValue,
          [currentValue]: currentIndex === 0 ? zod.string().optional() : zod.string(),
        }),
        {},
      ),
      // other attributes
      ...Object.values(restAttr).reduce((acc, attribute) => {
        return {
          ...acc,
          [attribute.contractName]: zod
            .any()
            .optional()
            .refine(...getRefineSystemRequired(attribute)),
        }
      }, {}),

      wrikeConnectionToggle: zod.boolean().optional(),
      wrikeProjectId: zod.string().optional(),
    })
    .refine(
      data => {
        if (data.wrikeConnectionToggle) {
          return /^\d{10}$/.test(data.wrikeProjectId!)
        }
        return true
      },
      {
        message: 'Wrike project ID must have 10 digits.',
        path: ['wrikeProjectId'],
      },
    )
    .refine(
      ({ name, description, type, dueDate, processType, wrikeConnectionToggle, wrikeProjectId, ...rest }) => {
        if (type === ProjectType.MISCELLANEOUS || type === ProjectType.FINANCE) {
          return true
        }

        const attributesKeys = Object.values(attributes).map(attribute => attribute.contractName)
        const notAttributes = Object.keys(rest)
          .filter(key => !attributesKeys.includes(key))
          .map(key => rest[key as keyof typeof rest])

        return Object.values(notAttributes).some(Boolean)
      },
      {
        message: `Please enter ${otherFields[0].toLowerCase()} of your project.`,
        path: [otherFields[0]],
      },
    )
}

type FiltersAmount<T> = {
  [K in keyof T]: T[K][] | undefined | string | boolean
}

export const getAppliedFilters = <T>(filters: FiltersAmount<T>): number => {
  return Object.values(filters)
    .filter(value => {
      if (typeof value === 'boolean') {
        return value
      }
      return Array.isArray(value) && value.length > 0
    })
    .flat().length
}
