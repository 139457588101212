import { projectApi } from 'api'
import { PaginatedResponse, PaginationParams } from 'api/common/types'
import { LOAD_ALL } from 'providers/project/ProjectProvider'
import { Project, ProjectStatus } from 'types/projects/projects'

interface Params {
  status: ProjectStatus[]
  tenant?: string[]
}

export const fetchProjectsApi = ({ page = 1, itemsPerPage = LOAD_ALL, status, tenant }: PaginationParams<Params>) =>
  projectApi.post<PaginatedResponse<Project>>(
    '/projects/search',
    {
      'filter[status]': status,
      ...(tenant?.length && { 'filter[tenant]': tenant }),
    },
    {
      params: {
        page,
        itemsPerPage,
        status,
      },
    },
  )
