// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.n16Mv{--wpp-typography-color: var(--wpp-grey-color-100);display:flex;flex-direction:column;padding:8px 12px;background:var(--wpp-grey-color-800);border-radius:6px}.JAKTH{--wpp-typography-color: var(--wpp-white-color)}`, "",{"version":3,"sources":["webpack://./src/pages/project/components/timeline/components/tooltipContent/TooltipContent.module.scss"],"names":[],"mappings":"AAAA,OACE,iDAAA,CAEA,YAAA,CACA,qBAAA,CACA,gBAAA,CACA,oCAAA,CACA,iBAAA,CAGF,OACE,8CAAA","sourcesContent":[".tooltipContainer {\n  --wpp-typography-color: var(--wpp-grey-color-100);\n\n  display: flex;\n  flex-direction: column;\n  padding: 8px 12px;\n  background: var(--wpp-grey-color-800);\n  border-radius: 6px;\n}\n\n.name {\n  --wpp-typography-color: var(--wpp-white-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltipContainer": `n16Mv`,
	"name": `JAKTH`
};
export default ___CSS_LOADER_EXPORT___;
