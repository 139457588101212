const getTimelineRow = (index: number) => {
  return document.querySelector(`.ganttWrapper g.rows > rect:nth-child(${index + 1})`)
}

export const highlightRow = (index: number) => {
  getTimelineRow(index)?.classList.add('rowHover')
}

export const unHighlightRow = (index: number) => {
  getTimelineRow(index)?.classList.remove('rowHover')
}
