import * as zod from 'zod'

import { ProjectAttribute } from 'types/projects/attributes'

export const addAttributesSchema = (attList: ProjectAttribute[]) =>
  zod
    .object({
      name: zod
        .string()
        .min(3, 'This field is required and should have at least 3 symbols')
        .max(100, 'Name should be from 3 to 100 characters long'),
      helperText: zod
        .string()
        .min(3, 'Helper text should contain at least 3 symbols')
        .max(100, 'Helper text should be from 3 to 100 characters long')
        .optional()
        .or(zod.literal('')),
      iconText: zod
        .string()
        .min(3, 'Info icon text should contain at least 3 symbols')
        .max(100, 'Info icon text should be from 3 to 100 characters long')
        .or(zod.literal('')),
      externalKey: zod
        .string()
        .min(3, 'External key should contain at least 3 symbols')
        .max(100, 'External key should be from 3 to 100 characters long')
        .or(zod.literal('')),
    })
    .refine(
      data => {
        if (data.name.trim().length >= 3)
          return !attList.some(att => att.name.toLowerCase() === data.name.trim().toLowerCase())
        return true
      },
      {
        message: 'Name already exists. Use a different one',
        path: ['name'],
      },
    )
