// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nWo4_{display:flex;flex-direction:column;flex-grow:1;width:100%;padding-bottom:16px}.AqeyT{flex-grow:1}`, "",{"version":3,"sources":["webpack://./src/pages/project/components/timeline/Timeline.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,qBAAA,CACA,WAAA,CACA,UAAA,CACA,mBAAA,CAGF,OACE,WAAA","sourcesContent":[".timelineContainer {\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  width: 100%;\n  padding-bottom: 16px;\n}\n\n.timelineSkeleton {\n  flex-grow: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"timelineContainer": `nWo4_`,
	"timelineSkeleton": `AqeyT`
};
export default ___CSS_LOADER_EXPORT___;
