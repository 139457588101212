import { useMemo } from 'react'

import { FormSelect } from 'components/form/formSelect/FormSelect'
import { ProjectAttribute } from 'types/projects/attributes'

interface Props {
  attribute: ProjectAttribute
}

export function SingleSelectAttribute({ attribute }: Props) {
  const statusOptions = useMemo(() => {
    return attribute.valueOptions?.map(option => ({
      value: option,
      label: option,
    }))
  }, [attribute.valueOptions])

  return (
    <FormSelect
      name={attribute.contractName}
      type="single"
      data-testid={`modal-project-${attribute.contractName}`}
      options={statusOptions || []}
      labelConfig={{
        text: attribute.name,
        ...(attribute.iconText && {
          icon: 'wpp-icon-info',
          description: attribute.iconText,
        }),
      }}
      message={attribute.helperText}
      required={attribute?.required ?? true}
      disabled={attribute?.enabled === false}
    />
  )
}
