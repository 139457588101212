import { UserDetails, MayBeNull } from '@wpp-open/core'

import { LAUNCH_LOCATIONS } from 'constants/analytics'
import { LinearData } from 'pages/project/components/canvas/utils'
import { DefaultHierarchyLevelType } from 'types/core'
import { Project } from 'types/projects/projects'
import { ApplicationItem, ExternalAppVersion } from 'types/projects/workflow'

interface Props {
  application: ApplicationItem
  userDetails: UserDetails
  isNoCodeApp: boolean
  selectedVersion: ExternalAppVersion | undefined
  project: Project
  phaseId: MayBeNull<string | undefined>
  linearData: LinearData
}

export const getEventTrackParams = ({
  application,
  userDetails,
  isNoCodeApp,
  selectedVersion,
  project,
  phaseId,
  linearData,
}: Props) => {
  const { contextHierarchy } = project

  const contextHierarchyMapping: { [key: string]: string } = contextHierarchy.reduce(
    (acc, { title, name }) => ({ ...acc, [title]: name }),
    {},
  )

  const customHierarchyLevel = contextHierarchy
    .filter(
      obj =>
        !Object.values(DefaultHierarchyLevelType).includes(obj.title.toUpperCase() as DefaultHierarchyLevelType) &&
        obj.name,
    )
    .map(custom => ({
      levelCategory: custom?.title,
      levelValue: custom?.name,
    }))

  const client = contextHierarchyMapping[DefaultHierarchyLevelType.Client.toLowerCase()]
  const market = contextHierarchyMapping[DefaultHierarchyLevelType.Market.toLowerCase()]
  const brand = contextHierarchyMapping[DefaultHierarchyLevelType.Brand.toLowerCase()]
  const industry = contextHierarchyMapping[DefaultHierarchyLevelType.Industry.toLowerCase()]
  const region = contextHierarchyMapping[DefaultHierarchyLevelType.Region.toLowerCase()]

  return {
    source: 'ORCHESTRATION',
    launchedWithContext: !!(client || market || brand || industry || region || customHierarchyLevel?.length),
    market: market || '',
    brand: brand || '',
    client: client || '',
    industry: industry || '',
    region: region || '',
    custom: customHierarchyLevel || [],
    productName: application.externalAppName,
    productType: application.type,
    appId: application.externalAppId || '',
    appInstanceId: application.appInstanceId,
    appInstanceName: application.externalAppOriginName,
    userId: userDetails.id,
    userEmail: userDetails.email,
    versionId: !isNoCodeApp ? selectedVersion?.id || '' : '',
    versionName: !isNoCodeApp ? selectedVersion?.name || '' : '',
    versionType: !isNoCodeApp ? selectedVersion?.versionType || '' : '',
    projectId: project?.id || '',
    projectName: project?.name || '',
    projectStatus: project?.status || '',
    projectType: project?.type || '',
    projectProcessType: project?.processType || '',
    phaseId: phaseId || '',
    phaseName: phaseId ? linearData.phases[phaseId].name : '',
    taskId: application.task?.id || '',
    taskStatus: application.task?.status || '',
    taskName: '',
    launchedFrom: LAUNCH_LOCATIONS.ProjectWorkflow,
    hub_name: '',
    hub_id: '',
    is_system_hub: '',
  }
}
