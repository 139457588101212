import { WppTypography } from '@platform-ui-kit/components-library-react'
import { format } from 'date-fns'
import { Task as GanttTask } from 'gantt-task-react'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useProject } from 'hooks/useProject'
import styles from 'pages/project/components/timeline/components/tooltipContent/TooltipContent.module.scss'
import { TimelineContext } from 'pages/project/components/timeline/TimelineProvider'
import { fullName } from 'utils/common'
import { DATE_FORMAT } from 'utils/dateFormat'

interface Props {
  task: GanttTask
}

export const TooltipContent = ({ task: ganttTask }: Props) => {
  const { allTasks, allPhases } = useContext(TimelineContext)
  const { members } = useProject()
  const { t } = useTranslation()

  const tasksAndPhasesCombined = [...allTasks, ...allPhases]

  const entity = tasksAndPhasesCombined.find(task => task.id === ganttTask.id)

  const userName = useMemo(() => {
    if (!entity || !entity.assignee) return ''
    const assignee = members.find(member => member.email === entity.assignee)

    return fullName(
      assignee?.firstname || assignee?.wrikeExternalFirstName!,
      assignee?.lastname || assignee?.wrikeExternalLastName!,
    )
  }, [entity, members])

  if (!entity) {
    return null
  }

  return (
    <div className={styles.tooltipContainer}>
      <WppTypography tag="p" type="m-midi" className={styles.name}>
        {entity.name}
      </WppTypography>

      {entity.startDate && (
        <WppTypography tag="p" type="s-body">
          {format(new Date(entity.startDate), DATE_FORMAT.D_LLL)}
          &nbsp;-&nbsp;
          {format(new Date(entity.endDate!), DATE_FORMAT.D_LLL)}
        </WppTypography>
      )}

      <WppTypography tag="p" type="s-body">
        {t('timeline.tooltipContent.status')}

        {t(`project.tasks.status.${entity.status}`)}
      </WppTypography>

      {entity.assignee && (
        <WppTypography tag="p" type="s-body">
          {t('timeline.tooltipContent.assignee', {
            assignee: userName,
          })}
        </WppTypography>
      )}
    </div>
  )
}
