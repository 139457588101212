import { HierarchyCustomNodeType } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'
import { format } from 'date-fns'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useFetchWorkflowTemplatesCountApi } from 'api/templates/queries/useFetchWorkflowTemplatesCountApi'
import { useHierarchy } from 'hooks/useHierarchy'
import { useToast } from 'hooks/useToast'
import { ProjectAttribute, ProjectAttributeType } from 'types/projects/attributes'
import { Project } from 'types/projects/projects'

export type ProjectDate = Pick<Project, 'startDate' | 'endDate'>

export const projectDateFormat = 'yyyy-MM-dd'

export const normalizeProjectFormDate = ([startDate, endDate]: Date[]): ProjectDate => ({
  startDate: startDate && startDate.getTime() !== endDate?.getTime() ? format(startDate, projectDateFormat) : null,
  endDate: endDate ? format(endDate, projectDateFormat) : null,
})

export const getInitialProjectFormDate = ({ startDate, endDate }: ProjectDate): Date[] => {
  const date = []

  //FYI: DatePicker range component requires 2 dates, so if no start date - we need to provide for UI endDate - endDate range which is interpreted as endDate.
  startDate && date.push(new Date(startDate))
  !startDate && endDate && date.push(new Date(endDate))
  endDate && date.push(new Date(endDate))

  return date
}

export const useCreateProjectTemplatesCount = () => {
  const { showToast } = useToast()
  const { t } = useTranslation()
  const {
    data: templatesCount,
    isLoading: isTemplatesCountLoading,
    isError: counterError,
  } = useFetchWorkflowTemplatesCountApi()

  const [isErrorToastDisplayed, setIsErrorToastDisplayed] = useState(false)

  useEffect(() => {
    if (counterError && !isErrorToastDisplayed) {
      showToast({
        type: 'error',
        message: t('project.canvas.toast.failed_workflow_count'),
      })
      setIsErrorToastDisplayed(true)
    } else if (!counterError && isErrorToastDisplayed) {
      // Clear the error toast if there's no error and the toast is displayed
      setIsErrorToastDisplayed(false)
    }
  }, [counterError, isErrorToastDisplayed, showToast, t])

  return { templatesCount, isTemplatesCountLoading, counterError }
}

export const fixDefaultValueForForm = (attribute: ProjectAttribute) => {
  let defaultValue = attribute.defaultValue
  // update defaults values for the form compatability
  switch (attribute.type) {
    case ProjectAttributeType.TEXT_INPUT:
    case ProjectAttributeType.TEXT_AREA:
      defaultValue = attribute.defaultValue || ''
      break

    case ProjectAttributeType.DATE_RANGE:
      defaultValue = attribute.defaultValue || []
      break
  }

  return { ...attribute, defaultValue }
}

/**
 * Selects the default hierarchy values in case of the only available option
 */
export const useHierarchyDefaults = () => {
  const {
    osContext: { navigationTree },
  } = useOs()
  const { hierarchyOrder } = useHierarchy()

  return useMemo(() => {
    return hierarchyOrder.reduce((acc, levelType, currentIndex) => {
      let preselectedId = ''
      // first level
      if (!currentIndex) {
        const nodes = Object.values(navigationTree.mapping).filter(node => {
          const type = (node.type === HierarchyCustomNodeType ? node.customTypeName : node.type)!.toLowerCase()
          return type === levelType
        })
        preselectedId = nodes.length === 1 ? nodes[0].azId! : ''
      } else {
        // children cascade
        const prevLevel = hierarchyOrder[currentIndex - 1]
        const prevAzId = acc[prevLevel]
        const childrenAzIds = navigationTree.mapping[prevAzId]?.children ?? []

        preselectedId = childrenAzIds.length === 1 ? childrenAzIds[0] : ''
      }

      return { ...acc, [levelType]: preselectedId }
    }, {} as Record<string, string>)
  }, [hierarchyOrder, navigationTree.mapping])
}
