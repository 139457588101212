import { HierarchyLevelType, HierarchyNode, NavigationTreeNode } from '@wpp-open/core'
import { HierarchyCustomNodeType } from '@wpp-open/core/types/mapping/common'
import { useOs } from '@wpp-open/react'
import { useMemo } from 'react'

import { FormSelectOption } from 'components/form/formSelect/FormSelect'
import { ProjectFilter } from 'types/projects/projects'

export const mapToOptions = (nodes: NavigationTreeNode[] = []): FormSelectOption[] => {
  const mapValues = nodes.reduce((previousValue: Record<string, string[]>, currentValue: NavigationTreeNode) => {
    const { name } = currentValue

    if (!name) return previousValue
    return { ...previousValue, [name]: [...(previousValue[name] || []), currentValue.azId] as string[] }
  }, {})

  return Object.keys(mapValues)
    .map(key => ({ value: mapValues[key].join(','), label: key }))
    .sort((a, b) => a.label?.localeCompare(b.label))
}

export const useHierarchyFilters = () => {
  const { osContext } = useOs()
  const { mapping } = osContext.navigationTree
  const { hierarchyLevels } = osContext.tenant

  const mappedTree = useMemo(
    () =>
      Object.keys(mapping).reduce((previousValue, currentValue) => {
        const element = mapping[currentValue]

        const type = (element.type === HierarchyCustomNodeType ? element.customTypeName : element.type) as string

        return { ...previousValue, [type]: [...(previousValue[type] || []), element] }
      }, {} as Record<HierarchyLevelType, NavigationTreeNode[]>),
    [mapping],
  )

  const options = useMemo(() => {
    return Object.entries(mappedTree).reduce((prev, [key, nodes]) => {
      return { ...prev, [key.toLowerCase()]: mapToOptions(nodes) }
    }, {} as Record<HierarchyLevelType, FormSelectOption[]>)
  }, [mappedTree])

  const getType = (node: HierarchyNode) => (node.type === HierarchyCustomNodeType ? node.customTypeName : node.type)!

  const allowNavigationTypes = useMemo(() => osContext.tenant.hierarchyLevels.map(lvl => lvl.type), [osContext])

  const getAllChildren = (value: Partial<ProjectFilter>, nodes: string[], isParentSelected = false) => {
    return nodes.reduce((acc: string[], curNodeId: string): string[] => {
      const currentNode = mapping[curNodeId] as HierarchyNode
      const currentNodeChildren = currentNode?.children || []

      // We need only HierarchyLevelType projects
      if (!allowNavigationTypes.includes(getType(currentNode))) return acc

      // Get selected nodes from form value
      const selectedNodes = ((value[getType(currentNode).toLowerCase() as keyof ProjectFilter] as string[]) || [])
        .map(el => el.split(','))
        .flat(Infinity) as string[]

      const checkIfNodeHasSelectedChildren = (currentHierarchyIndex: number) => {
        const selectedOnNextLvl = []
        for (let index = currentHierarchyIndex + 1; index < hierarchyLevels.length; index++) {
          const lvl = hierarchyLevels[index].type
          const nextLvlValue = value[lvl.toLowerCase() as keyof ProjectFilter]

          selectedOnNextLvl.push(!!nextLvlValue?.length)
        }

        return selectedOnNextLvl.some(Boolean)
      }

      const currentHierarchyIndex = hierarchyLevels.findIndex(h => h.type === getType(currentNode))
      const nodeHasSelectedChildren = checkIfNodeHasSelectedChildren(currentHierarchyIndex)

      if (!!selectedNodes.length) {
        const isThisNodeSelected = selectedNodes.includes(currentNode.azId)

        if (isThisNodeSelected) {
          if (!!currentNodeChildren.length) {
            return acc.concat(
              nodeHasSelectedChildren ? [] : currentNode.azId,
              getAllChildren(value, currentNodeChildren, true),
            )
          } else {
            if (currentHierarchyIndex === hierarchyLevels.length - 1) return acc.concat(currentNode.azId)
            return nodeHasSelectedChildren ? acc : acc.concat(currentNode.azId)
          }
        } else return acc
      } else {
        if (!!currentNodeChildren.length) {
          return isParentSelected
            ? acc.concat(
                nodeHasSelectedChildren ? [] : currentNode.azId,
                getAllChildren(value, currentNodeChildren, isParentSelected),
              )
            : acc.concat(getAllChildren(value, currentNodeChildren, isParentSelected))
        } else {
          if (currentHierarchyIndex >= hierarchyLevels.length - 1)
            return isParentSelected ? acc.concat(currentNode.azId) : acc

          return nodeHasSelectedChildren ? acc : acc.concat(currentNode.azId)
        }
      }
    }, [])
  }

  return { options, getAllChildren }
}
