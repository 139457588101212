import { projectApi } from 'api'
import { PaginatedResponse, PaginationParams } from 'api/common/types'
import { Project, ProjectFilter } from 'types/projects/projects'

export const fetchProjectsListApi = ({
  search,
  ownership,
  type,
  workspace,
  status,
  tenant,
  page = 1,
  itemsPerPage = 30,
}: PaginationParams<Omit<ProjectFilter, 'client' | 'market' | 'brand'>>) =>
  projectApi.post<PaginatedResponse<Project>>(
    '/projects/search',
    {
      name: search,
      'filter[ownership]': ownership,
      'filter[type]': type,
      'filter[workspace]': workspace,
      'filter[status]': status,
      'filter[tenant]': tenant,
    },
    {
      params: {
        page,
        itemsPerPage,
      },
    },
  )
