import { WppTypography, WppEmptyNothingFound, WppEmptyContent } from '@platform-ui-kit/components-library-react'
import { ReactElement, cloneElement } from 'react'

import styles from 'components/common/emptyState/EmptyState.module.scss'
import { Flex } from 'components/common/flex/Flex'

type Props = {
  title?: string
  description?: string
  testToken?: string
  icon?: React.ReactNode
  filtersApplied?: boolean
  noRecordsIcon?: ReactElement
  children?: React.ReactNode
} & JSX.IntrinsicElements['div']

interface CustomIconWrapperProps {
  children: ReactElement
  testToken?: string
}

// Custom wrapper component for noRecordsIcon
const CustomIconWrapper: React.FC<CustomIconWrapperProps> = ({ children, testToken, ...otherProps }) => {
  return cloneElement(children, { 'data-testid': `no-results-${testToken}-logo`, ...otherProps })
}

export default CustomIconWrapper
export const EmptyState = ({ title, description, testToken, filtersApplied, noRecordsIcon, children }: Props) => {
  return (
    <Flex className={styles.noData} align="center" justify="center" direction="column" gap={24}>
      {filtersApplied ? (
        <WppEmptyNothingFound data-testid={`no-results-${testToken}-logo`} width={120} />
      ) : noRecordsIcon ? (
        <CustomIconWrapper testToken={testToken}>{noRecordsIcon}</CustomIconWrapper>
      ) : (
        <WppEmptyContent data-testid={`no-${testToken}-logo`} width={120} />
      )}
      <Flex justify="center" align="center" direction="column" gap={8} className={styles.emptyStateTextWrapper}>
        <WppTypography type="m-strong" data-testid={`no-${testToken}-title`}>
          {title}
        </WppTypography>
        <WppTypography type="xs-body" data-testid={`no-${testToken}-description`} className={styles.greyColor800}>
          {description}
        </WppTypography>
      </Flex>
      {children}
    </Flex>
  )
}
