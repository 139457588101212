import { FormInput } from 'components/form/formInput/FormInput'
import { ProjectAttribute } from 'types/projects/attributes'

interface Props {
  attribute: ProjectAttribute
}

export function TextInputAttribute({ attribute }: Props) {
  return (
    <FormInput
      name={attribute.contractName}
      data-testid={`modal-project-${attribute.contractName}`}
      labelConfig={{
        text: attribute.name,
        ...(attribute.iconText && {
          icon: 'wpp-icon-info',
          description: attribute.iconText,
        }),
      }}
      message={attribute.helperText}
      required={attribute?.required ?? true}
      disabled={attribute?.enabled === false}
    />
  )
}
