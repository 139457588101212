import { WppButton, WppLabel, WppTypography } from '@platform-ui-kit/components-library-react'
import { useMemo } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useCreateWorkflowTemplateApi } from 'api/canvas/mutation/useCreateWorkflowTemplateApi'
import { Flex } from 'components/common/flex/Flex'
import { FormInput } from 'components/form/formInput/FormInput'
import { FormSelect } from 'components/form/formSelect/FormSelect'
import { FormTextareaInput } from 'components/form/formTextareaInput/FormTextareaInput'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useForm } from 'hooks/form/useForm'
import { useToast } from 'hooks/useToast'
import { TemplateTagControl } from 'pages/project/components/canvas/components/saveAsTemplateModal/TemplateTagControl'
import { SelectProcessType } from 'pages/project/components/canvas/components/selectProcessType/SelectProcessType'
import styles from 'pages/templates/components/createNewTemplateModal/CreateNewTemplateModal.module.scss'
import {
  VALIDATION,
  FORM_DEFAULTS,
  templateValidationSchema,
  ProjectTypeOption,
} from 'pages/templates/components/createNewTemplateModal/utils'
import { queryClient } from 'providers/osQueryClient/utils'
import { ProjectType } from 'types/projects/projects'
import { NiceModalWrappedProps, createNiceModal } from 'utils/createNiceModal'
import { is409Error } from 'utils/error'
import { routesManager } from 'utils/routesManager'

const CreateNewTemplateModal = ({ isOpen, onClose, onCloseComplete }: NiceModalWrappedProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const form = useForm({ defaultValues: FORM_DEFAULTS, validationSchema: templateValidationSchema })
  const { showToast } = useToast()

  const { mutateAsync: createTemplate } = useCreateWorkflowTemplateApi()

  const typeOptions: ProjectTypeOption[] = useMemo(() => {
    return (Object.keys(ProjectType) as ProjectType[]).map(type => ({
      value: type,
      // keep it in sync with `modals.save_project_as_template` translation node
      label: t(`modals.save_project_as_template.project_type_${type.toLowerCase()}`),
    }))
  }, [t])

  const {
    handleSubmit,
    formState: { isSubmitting, isValid },
    getValues,
    trigger,
  } = form

  const onSubmit = handleSubmit(async () => {
    if (!isValid) {
      await trigger()
      return
    }
    handleSave()
  })

  const handleSave = async () => {
    try {
      const { name, description, projectTypes, tags, processType } = getValues()

      const {
        data: { id },
      } = await createTemplate({
        name: name.trim(),
        types: projectTypes,
        processType,
        description: description?.trim() || undefined,
        tags,
      })

      queryClient.invalidateQueries([ApiQueryKeys.WORKFLOW_TEMPLATES])
      queryClient.invalidateQueries([ApiQueryKeys.WORKFLOW_TEMPLATES_INFINITE])

      showToast({
        type: 'success',
        message: t('modals.create_new_template.toast_success_message'),
      })
      navigate(routesManager.template.root.getURL({ id }))
      onClose()
    } catch (e) {
      const is409 = is409Error(e)
      let message = t('modals.save_project_as_template.toast_error_common')
      if (is409) message = t('modals.save_project_as_template.toast_error_duplicate')

      showToast({
        type: 'error',
        message,
      })
      console.error(e)
    }
  }

  return (
    <FormProvider {...form}>
      <SideModal
        open={isOpen}
        formConfig={{ onSubmit }}
        onWppSideModalClose={onClose}
        onWppSideModalCloseComplete={onCloseComplete}
        size="m"
        data-testid="save-as-tpl-modal"
      >
        <WppTypography slot="header" type="2xl-heading">
          {t('modals.create_new_template.title')}
        </WppTypography>
        <Flex slot="body" direction="column" gap={24} className={styles.container}>
          <FormInput
            name="name"
            placeholder={t('modals.create_new_template.field_name_placeholder')!}
            labelConfig={{ text: t('modals.create_new_template.field_name_label') }}
            required
            data-testid="save-as-tpl-modal-name"
          />

          <FormSelect
            name="projectTypes"
            type="multiple"
            options={typeOptions}
            placeholder={t('modals.create_new_template.field_project_placeholder')!}
            labelConfig={{ text: t('modals.create_new_template.field_project_label') }}
            data-testid="save-as-tpl-modal-type"
          />

          <Flex direction="column" gap={8}>
            <WppLabel
              data-testid="template-process-type-label"
              config={{ text: t('modals.create_new_template.field_process_type_label') }}
              typography="s-strong"
            />
            <SelectProcessType name="processType" />
          </Flex>

          <FormTextareaInput
            name="description"
            warningThreshold={VALIDATION.description.max - 20}
            placeholder={t('modals.save_project_as_template.field_description_placeholder')!}
            labelConfig={{ text: t('modals.save_project_as_template.field_description_label') }}
            charactersLimit={VALIDATION.description.max}
            data-testid="save-as-tpl-modal-description"
          />

          <Flex direction="column" gap={4}>
            <WppTypography type="m-strong">{t('modals.create_new_template.template_visibility')}</WppTypography>
            <WppTypography type="s-body" className={styles.greyColor700}>
              {t('modals.create_new_template.template_visibility_description')}
            </WppTypography>
          </Flex>

          <TemplateTagControl />
        </Flex>
        <Flex slot="actions" justify="end" gap={12}>
          <WppButton variant="secondary" size="m" onClick={() => onClose()}>
            {t('common.btn_cancel')}
          </WppButton>
          <WppButton variant="primary" size="m" type="submit" loading={isSubmitting}>
            {t('common.btn_create')}
          </WppButton>
        </Flex>
      </SideModal>
    </FormProvider>
  )
}

export const { showModal: showCreateNewTemplateModal } = createNiceModal<NiceModalWrappedProps>(
  CreateNewTemplateModal,
  'create-new-template-modal',
)
