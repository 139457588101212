import { MayBeNull } from '@wpp-open/core'
import { HierarchyLevelType } from '@wpp-open/core/types/tenant'

import { DefaultHierarchyLevelType } from 'types/core'
import { Members } from 'types/members/members'
import { ProjectFromTenantDTO } from 'types/projects/fromTenant'
import { ProjectMember } from 'types/projects/projectMembers'
import { PhaseStatus } from 'types/projects/workflow'

export interface Project {
  id: string
  name: string
  description?: string
  processType: ProcessType
  createdAt: string
  startDate?: MayBeNull<string>
  endDate?: MayBeNull<string>
  updatedAt?: MayBeNull<string>
  contextHierarchy: ContextHierarchy[]
  contextWorkspace?: string
  type: ProjectType
  status: ProjectStatus
  ownerEmail: string
  owner?: Members
  members?: Members[]
  owners?: Members[]
  uncompletedTasksCount: number
  phases: ProjectShortPhaseDTO[]
  wrike?: MayBeNull<ProjectWrikeDTO>
  tenant: MayBeNull<ProjectFromTenantDTO>
}

interface ProjectShortPhaseDTO {
  id: string
  name: string
  orderNumber: number
  status: PhaseStatus
}

export interface ProjectWrikeDTO {
  wrikeProjectId: string
  name: string
  status: string
  permalink: string
  connectedAt: string
  connectedByEmail: string
  synchronizedAt: MayBeNull<string>
  disconnectedAt: MayBeNull<string>
  isConnected: boolean
  isSuspended: boolean
  connectedBy: MayBeNull<ProjectMember>
  accountName: MayBeNull<string>
}

export interface ContextHierarchy {
  title: HierarchyLevelType
  name: MayBeNull<string>
  value: MayBeNull<string>
}

export type AllHierarchyLevels = Record<DefaultHierarchyLevelType, string[]>

type LowercaseKeys<T> = {
  [K in keyof T as Lowercase<string & K>]: T[K]
}

export interface LowercaseHierarchyLevels extends Partial<LowercaseKeys<AllHierarchyLevels>> {}

export interface ProjectFilter extends LowercaseHierarchyLevels {
  search?: string
  ownership?: ProjectOwnership
  status: ProjectStatus[]
  type: ProjectType[]
  workspace?: string[]
  tenant?: string[]
}

export enum TenantSubset {
  ALL_ACCESSIBLE = 'ALL_ACCESSIBLE',
  CURRENT = 'CURRENT',
}

export interface TemplateFilter {
  inputText?: string
  processType?: ProcessType
  tenant?: string[]
  tenantSubset?: TenantSubset
}

export enum ProjectType {
  CAMPAIGN = 'CAMPAIGN',
  WORKSHOP = 'WORKSHOP',
  PITCH = 'PITCH',
  FINANCE = 'FINANCE',
  MISCELLANEOUS = 'MISCELLANEOUS',
}

export enum ProcessType {
  LINEAR = 'LINEAR',
  FLUID = 'FLUID',
}

export enum ProjectStatus {
  TO_DO = 'TO_DO',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  ARCHIVED = 'ARCHIVED',
}

export enum AdditionalProjectStatus {
  WORKSPACE_ERROR = 'WORKSPACE_ERROR',
  PROJECT_SETUP_PENDING = 'PROJECT_SETUP_PENDING',
  WRIKE_NO_CONNECTED = 'WRIKE_NO_CONNECTED',
  WRIKE_NO_ACCESS = 'WRIKE_NO_ACCESS',
}

export type BannerStatus = AdditionalProjectStatus | ProjectStatus

export enum ProjectOwnership {
  ALL = 'ALL',
  MY = 'MY',
  SHARED_WITH_ME = 'SHARED_WITH_ME',
}
