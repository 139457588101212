import { HierarchyLevelType } from '@wpp-open/core'
import { useTranslation } from 'react-i18next'

import { FormSelect } from 'components/form/formSelect/FormSelect'
import { useHierarchy } from 'hooks/useHierarchy'
import styles from 'pages/dashboard/Dashboard.module.scss'
import { useHierarchyFilters } from 'pages/dashboard/utils/hierarchyFilters'
import { capitalizeFirstLetter } from 'utils/common'

const locale = {
  emptyText: 'Nothing Found',
  clearAllText: 'Clear All',
  selectAllText: 'Select All',
  searchInputPlaceholder: 'Search',
  allSelectedText: 'All selected',
  selectLabel: 'selected',
}

export const HierarchyFilterControl = () => {
  const { t } = useTranslation()

  const { hierarchyOrder } = useHierarchy()
  const { options } = useHierarchyFilters()

  return (
    <>
      {hierarchyOrder?.map(hierarchy => {
        const op = options?.[hierarchy as HierarchyLevelType] || []
        const allSelectedText = op.length === 1 ? op[0].label : 'All selected'

        return (
          <FormSelect
            key={hierarchy}
            name={hierarchy}
            withSearch
            className={styles.hierarchySelect}
            type="multiple"
            data-testid={`${hierarchy.toLowerCase()}-select`}
            options={op}
            labelConfig={{
              text: t(`modals.dashboard_filters.field_${hierarchy.toLowerCase()}_label`, {
                defaultValue: capitalizeFirstLetter(hierarchy),
              }),
            }}
            placeholder={
              t(`modals.create_project.field_${hierarchy.toLowerCase()}_placeholder`, {
                defaultValue: capitalizeFirstLetter(hierarchy),
              })!
            }
            locales={{
              ...locale,
              allSelectedText,
            }}
            withFolder
            required
          />
        )
      })}{' '}
    </>
  )
}
