import { WppSpinner } from '@platform-ui-kit/components-library-react'
import { RowClassRules } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import clsx from 'clsx'
import { forwardRef, Ref, RefCallback, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { mergeRefs } from 'react-merge-refs'
import { useUpdateEffect } from 'react-use'

import { Flex } from 'components/common/flex/Flex'
import {
  getCellRenderer,
  getToolipValueGetter,
  getValueGetter,
  isLoadingMoreRow,
  TableInfiniteProps,
} from 'components/common/table'
import { ColDef, Table } from 'components/common/table/Table'
import styles from 'components/common/table/tableInfinite/TableInfinite.module.scss'
import { TableDefaults } from 'constants/table'
import { useInfiniteFetchNextPage } from 'hooks/useInfiniteFetchNextPage'
import { useStableCallback } from 'hooks/useStableCallback'
import { isDevelop, isNumber, noop } from 'utils/common'

export const TablePageInfinite = forwardRef(function TablePageInfinite<TData = any>(
  {
    rowClassRules,
    className,
    loader,
    onLoadSuccess = noop,
    onLoadError = noop,
    columnDefs,
    cacheOverflowSize = 5,
    cacheBlockSize = TableDefaults.CacheBlockSize,
    infiniteInitialRowCount = cacheBlockSize,
    ...rest
  }: TableInfiniteProps<TData>,
  ref: Ref<AgGridReact<TData>>,
) {
  const innerRef = useRef<AgGridReact<TData>>()
  const onLoadSuccessStable = useStableCallback(onLoadSuccess)
  const onLoadErrorStable = useStableCallback(onLoadError)

  const rowClassRulesInner = useMemo<RowClassRules<TData>>(
    () => ({
      ...rowClassRules,
      [[styles.loadingMoreRow, 'loading-more-row'].join(' ')]: ({ data, node }) =>
        isLoadingMoreRow<TData>({ data, node }),
    }),
    [rowClassRules],
  )

  const columnDefsInner = useMemo<ColDef<TData>[]>(
    () =>
      columnDefs.map(colDef => {
        const { cellRenderer, loadingCellRenderer, valueGetter, tooltipValueGetter, cellClassRules, ...rest } = colDef

        return {
          ...rest,
          ...(!!valueGetter && { valueGetter: getValueGetter<TData>(valueGetter) }),
          ...(!!tooltipValueGetter && { tooltipValueGetter: getToolipValueGetter<TData>(tooltipValueGetter) }),
          cellRenderer: getCellRenderer<TData>(colDef),
          cellClassRules: {
            ...cellClassRules,
            'infinite-default-cell': () => !cellRenderer,
          },
        }
      }),
    [columnDefs],
  )

  const [isFetchingNextPage, setIsFetchingNextPage] = useState(false)
  const [hasNextPage, setHasNextPage] = useState(true)

  const [page, setPage] = useState(1)
  const [gridData, setGridData] = useState<TData[]>([])

  const fetchData = useCallback(
    async (pageNumber: number) => {
      const hideOverlay = () => {
        innerRef.current?.api.hideOverlay()
      }

      setIsFetchingNextPage(true)

      const startRow = (pageNumber - 1) * cacheBlockSize
      const endRow = pageNumber * cacheBlockSize
      const isInitialLoading = startRow === 0

      try {
        hideOverlay()

        if (isInitialLoading) {
          // hack to show inline loading overlay
          setGridData([null, null, null] as any[])
        }

        const { data, totalRowsCount } = await loader({ startRow, endRow })

        const allData = isInitialLoading ? [...data] : [...gridData, ...data]
        setGridData(allData)
        setIsFetchingNextPage(false)

        setHasNextPage(allData.length < totalRowsCount)

        if (!totalRowsCount && isInitialLoading) {
          setTimeout(() => innerRef.current?.api.showNoRowsOverlay())
        } else {
          hideOverlay()
        }

        onLoadSuccessStable({
          startRow,
          endRow,
          data,
          totalRowsCount,
          isEmptySource: isInitialLoading && !totalRowsCount,
        })

        try {
          const renderedNodes = innerRef.current?.api.getRenderedNodes()

          const shouldReflowRows = renderedNodes?.some(
            ({ rowTop, rowHeight }, index, arr) =>
              index !== 0 && isNumber(rowTop) && isNumber(rowHeight) && rowTop - rowHeight !== arr[index - 1].rowTop,
          )

          if (shouldReflowRows) {
            innerRef.current?.api.redrawRows({ rowNodes: renderedNodes })
          }
        } catch (e) {
          if (isDevelop) {
            console.error('Rows reflow failed.')
          }
        }
      } catch (e) {
        if (isDevelop) {
          console.error(e)
        }
        hideOverlay()
        onLoadErrorStable(e)
      }
    },
    [cacheBlockSize, gridData, loader, onLoadErrorStable, onLoadSuccessStable],
  )

  const loadPage = useCallback(
    async (page: number) => {
      await fetchData(page)
      setPage(page + 1)
    },
    [fetchData],
  )

  const reloadData = useCallback(async () => {
    setGridData([])
    setPage(1)
    await loadPage(1)
  }, [loadPage])

  const reloadDataRef = useRef(reloadData)
  // we need to keep the reference to the latest reloadData function
  reloadDataRef.current = reloadData

  useEffect(() => {
    const gridApi = innerRef.current?.api
    gridApi?.setGridOption('rowData', gridData)
  }, [gridData])

  const [loadMoreRef, setLoadMoreRef] = useState<HTMLDivElement>(null!)
  const setRef: RefCallback<HTMLDivElement> = useCallback(node => setLoadMoreRef(node!), [])

  // refetch the data srouce when the loader changed
  useUpdateEffect(() => {
    reloadData()
  }, [loader])

  useInfiniteFetchNextPage({
    loadMoreRef,
    isFetchingNextPage,
    fetchNextPage: () => loadPage(page),
    hasNextPage,
  })

  const onGridReady = async () => {
    const gridApi = innerRef.current?.api
    gridApi?.addEventListener('refetch', () => {
      reloadDataRef.current?.()
    })

    await loadPage(1)
  }

  return (
    <>
      <Table
        {...rest}
        ref={mergeRefs([ref, innerRef])}
        className={clsx(styles.root, styles.pageSticky, className)}
        rowModelType="clientSide"
        cacheBlockSize={void 0}
        domLayout="autoHeight"
        columnDefs={columnDefsInner}
        rowClassRules={rowClassRulesInner}
        infiniteInitialRowCount={infiniteInitialRowCount}
        cacheOverflowSize={cacheOverflowSize}
        suppressRowClickSelection
        rowSelection="multiple"
        onGridReady={onGridReady}
      />

      {hasNextPage && page !== 1 && (
        <Flex justify="center" ref={setRef}>
          <WppSpinner size="l" />
        </Flex>
      )}
    </>
  )
}) as <TData = any>(props: { ref?: Ref<AgGridReact<TData>> } & TableInfiniteProps<TData>) => JSX.Element
