import { MayBeNull } from '@wpp-open/core'
import { XYCoord } from 'dnd-core'

import { isActivityItem, isApplicationItem } from 'pages/project/components/canvas/phaseUtils'
import {
  ActivityApplicationItem,
  ApplicationItem,
  Phase,
  PhaseItem,
  PhaseItemType,
  PhaseStatus,
  Workflow,
} from 'types/projects/workflow'

export interface DnDPhase {
  id: string
  index: number
}

export interface DnDItem {
  id: string
  index: number
  type: PhaseItemType
  phaseId: string
  height: number | undefined
}

export enum DragContainerType {
  Phase = 'phase',
  Item = 'item',
}

export type LinearPhase = Omit<Phase, 'phaseItems'> & { itemIds: string[] }

export interface LinearData {
  items: Record<string, PhaseItem | ActivityApplicationItem>
  phases: Record<string, LinearPhase>
  phaseOrder: string[]
}

export const initData: LinearData = {
  items: {},
  phases: {},
  phaseOrder: [],
}

function sortByOrder<T extends { orderNumber: number }>(items: T[]): T[] {
  return [...items].sort((a, b) => (a.orderNumber > b.orderNumber ? 1 : -1))
}

export const mapCanvasData = (data?: MayBeNull<Workflow>): LinearData => {
  const sortedCopyData = [...(data?.phases || [])].sort((a, b) => a.orderNumber - b.orderNumber)
  return (
    sortedCopyData.reduce((previousValue, currentValue) => {
      const sortedTask = currentValue.phaseItems.sort((a, b) => (a.orderNumber > b.orderNumber ? 1 : -1))

      const tasks = sortedTask.reduce((prev, curr) => {
        if (isActivityItem(curr)) {
          const activityItem = curr
          const sortedApps = sortByOrder(activityItem.item.items ?? []).map(item => ({
            ...item,
            activityItemId: activityItem.id,
          }))

          const innerTasks = sortedApps.reduce((innerAcc, innerAppItem) => {
            return {
              ...innerAcc,
              [innerAppItem.id]: innerAppItem,
            }
          }, {})

          return {
            ...prev,
            [curr.id]: {
              ...curr,
              item: {
                ...activityItem.item,
                items: sortedApps,
              },
            },
            ...innerTasks,
          }
        }
        return { ...prev, [curr.id]: curr }
      }, {})

      const { phaseItems, ...phase } = currentValue

      return {
        ...previousValue,
        items: {
          ...previousValue.items,
          ...tasks,
        },
        phases: {
          ...previousValue.phases,
          [currentValue.id]: {
            ...phase,
            itemIds: sortedTask.map(({ id }) => id),
          },
        },
        phaseOrder: [...previousValue.phaseOrder, currentValue.id],
      }
    }, initData) || initData
  )
}

export const mapLinearApps = (linearData: LinearData) => {
  return linearData.phaseOrder.reduce<Record<string, ApplicationItem>>((acc, order) => {
    const phaseItems = linearData.phases[order].itemIds.map(id => linearData.items[id]) as PhaseItem[]
    phaseItems.forEach(phaseItem => {
      if (isApplicationItem(phaseItem)) {
        acc[phaseItem.item.id] = phaseItem.item
        return
      }

      if (isActivityItem(phaseItem)) {
        phaseItem.item.items.forEach(item => {
          acc[item.application.id] = item.application
        })
        return
      }

      throw new Error(`Unknown item type ${phaseItem.itemType}`)
    })
    return acc
  }, {})
}

export enum DropPosition {
  Above = 'above',
  Below = 'below',
}

export function getDropPosition(hoverBoundingRect: DOMRect, clientOffset: XYCoord): DropPosition {
  const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

  // Get pixels to the top
  const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

  return hoverClientY <= hoverMiddleY ? DropPosition.Above : DropPosition.Below
}

export enum FluidAppPatchAction {
  Reorder = 'reorder',
  MoveIn = 'moveIn',
  MoveOut = 'moveOut',
}

export const getTagType = (phaseStatus: PhaseStatus) => {
  switch (phaseStatus) {
    case PhaseStatus.TO_DO:
      return { cat: 6, variant: 'neutral' }
    case PhaseStatus.IN_PROGRESS:
      return { cat: 6, variant: 'warning' }
    case PhaseStatus.COMPLETED:
      return { cat: 6, variant: 'positive' }
    default:
      return null
  }
}
