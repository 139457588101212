import { useMemo } from 'react'
import * as zod from 'zod'

import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useProject } from 'hooks/useProject'
import { LinearPreset } from 'pages/project/components/canvas/components/viewSettingsModal/presets'
import { queryClient } from 'providers/osQueryClient/utils'
import { PresetType, ProjectPartKey } from 'types/projects/projectViewSettings'
import { isValidUrl } from 'utils/link'

export const MAX_LINKS_COUNT = 20
export const externalLink = zod.object({
  url: zod.string().refine(url => isValidUrl(url), {
    message: 'Please specify a valid URL.',
  }),
  alias: zod.string().optional(),
})

export const activityValidationScheme = zod
  .object({
    name: zod
      .string()
      .min(1, 'Name should contain at least 1 character')
      .max(64, 'Name should have 64 characters at most'),

    description: zod.string().max(1000, 'Description should have 1000 characters at most').optional(),
  })
  .passthrough()

export const applicationValidationScheme = zod
  .object({
    name: zod.string().max(64, 'Name should have 64 characters at most'),
    description: zod.string().max(1000, 'Description should have 1000 characters at most').optional(),
  })
  .passthrough()

export const useUiPartEnabled = (isEditable?: boolean) => {
  const projectContext = useProject()

  const config = useMemo(
    () =>
      projectContext && projectContext.viewSettings
        ? projectContext.viewSettings
        : {
            ...LinearPreset[PresetType.DEFAULT],
            preset: PresetType.DEFAULT,
          },
    [projectContext],
  )

  return (key: ProjectPartKey) => {
    // all UI parts are available in the edit mode
    if (isEditable) return true

    return !!config && config[key]
  }
}

export const getFileFromUrl = (url: string) => {
  const regex = /[?&]file=([^&#]*)/i
  const match = url.match(regex)
  if (match && match[1]) {
    return decodeURIComponent(match[1])
  }
}

export const getProjectIdFromUrl = (url: string) => {
  const regex = /\/project\/([^/]+)/
  const match = url.match(regex)
  if (match && match[1]) {
    return match[1]
  }
}

export const invalidateCanvas = async (isMemberChanged?: boolean) => {
  await Promise.all(
    [
      isMemberChanged ? queryClient.invalidateQueries([ApiQueryKeys.MEMBERS]) : null,
      queryClient.invalidateQueries([ApiQueryKeys.PROJECT_WORKFLOW_LINEAR]),
      queryClient.invalidateQueries([ApiQueryKeys.PROJECT_WORKFLOW_FLUID]),
      queryClient.invalidateQueries([ApiQueryKeys.PROJECTS_DASHBOARD_WORKFLOW_WIDGET]),
      queryClient.invalidateQueries([ApiQueryKeys.PROJECT_TIMELINE]),
    ].filter(Boolean),
  )
}
