import { MayBeNull } from '@wpp-open/core'

import { projectApi } from 'api'
import { TaskStatus } from 'types/projects/tasks'
import { ActivityApplicationItem } from 'types/projects/workflow'

export interface ActivityPatchItemRequest {
  id: string
  name?: MayBeNull<string>
  description?: MayBeNull<string>
  startDate?: MayBeNull<string>
  endDate?: MayBeNull<string>
  assignUser?: MayBeNull<string>
  items?: ActivityApplicationItem[]
  taskStatus?: MayBeNull<TaskStatus>
  taskExternalStatusId?: MayBeNull<string>
  hidden?: boolean
}

export const patchActivityApi = ({ id, ...body }: ActivityPatchItemRequest) =>
  projectApi.patch(`/activities/${id}`, body)
