import { MayBeNull } from '@wpp-open/core'

export const isDevelop = process.env.NODE_ENV === 'development'
export const isTest = process.env.NODE_ENV === 'test'

export function excludeFalsy<T>(value: T): value is Exclude<T, false | null | undefined | '' | 0> {
  return Boolean(value)
}

export const isString = (value: any): value is string => typeof value === 'string'
export const isNumber = (value: any): value is number => typeof value === 'number' && !isNaN(value)
export const isFunction = (value: unknown): value is Function => typeof value === 'function'

export const join = (value: (string | null | number | undefined)[], separator: string) =>
  value.filter(v => v !== 0 && excludeFalsy(v)).join(separator)

export const noop = () => {}

// compare two strings in insensitive manner
export const isEqualEmails = (first?: MayBeNull<string>, second?: MayBeNull<string>) =>
  first?.toLowerCase() === second?.toLowerCase()

export const capitalizeFirstLetter = (word: string = '') => word.charAt(0).toUpperCase() + word.slice(1)

export const fullName = (firstname?: string, lastname?: string) => [firstname, lastname].join(' ').trim()

// n-3 bcs we add three dots
export const makeStringShorter = (s: string = '', n: number = 40) => (s.length > n ? `${s.substring(0, n)}...` : s)
